.redButton {
  background-color: rgb(225, 5, 0);
  min-height: 54px;
  min-width: 180px;
  border: none;
  color: white;
  font-size: 130%;
  border-radius: 10px;
  white-space: pre;
}
.redButton:hover {
  background-color: #ab2424;
  border-color: #ab2424;
}
.redButton:disabled {
  background-color: #c4c4c4;
  border-color: #c4c4c4;
}
