body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: #353b48;
  text-align: left;
  background-color: #faf9f9;
}
h3 {
  margin-bottom: 0.5rem;
  font-family: 'PT Sans', sans-serif;
  font-weight: 500;
  line-height: 1.2;
  font-size: 1.75rem;
  color: #868e96;
}
