.chartsBlock {
  display: flex;
  flex-direction: column;
  width: 48%;
}
.dateNow {
  position: absolute;
  left: 180px;
  font-size: 16px;
  font-weight: bold;
}

.delimiterWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.infoTextBlock {
  width: 48%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.container {
  width: 90%;
}
.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.textli {
  margin-bottom: 10px;
  line-height: 1.1;
  color: black;
}
.lineChartDaysWrapper {
  border: 2px dotted grey;
  border-radius: 30px;
  padding: 10px;
}
.indexBlock {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 22px;
}
.indexNumber {
  margin-left: 10px;
  font-size: 35px;
  font-weight: bold;
}
.redIndex {
  color: #d32f2f;
}
.greenIndex {
  color: green;
}
.border {
  width: 50px;
  height: 210px;
  border: 2px dotted grey;
  border-radius: 10px;
  position: absolute;
  top: 0%;
  left: 92%;
}

.lineChartWrapper {
  position: relative;
  margin-bottom: 10px;
  margin-top: 10px;
}
.textBlock {
  margin-bottom: 10px;
}
/* .lineChartWrapper::before {
  content: '............................';
  position: absolute;
  top: 30%;
  left: 101.5%;
  font-size: 13px;
  color: grey;
} */
.arrow {
  position: absolute;
  top: 31%;
  left: 107%;
  color: grey;
}
@media screen and (max-width: 1300px) {
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .infoTextBlock {
    width: 100%;
    min-width: 350px;
  }
  .chartsBlock {
    width: 100%;
    min-width: 350px;
  }
  .lineChartDaysWrapper {
    margin-bottom: 20px;
    width: 100%;
    min-width: 350px;
  }

  .arrow {
    top: -10%;
    left: 99%;
  }
  .dateNow {
    left: 140px;
  }
  .indexBlock {
    font-size: 16px;
  }
  .indexNumber {
    font-size: 29px;
  }
  .border {
    left: 88%;
  }
}
@media screen and (min-width: 1500px) {
  .dateNow {
    left: 240px;
  }
}
